import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PageHome',
    component: () => import('../views/PageHome.vue')
  },
  {
    path: '/PageSearch',
    name: 'PageSearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/PageSearch.vue')
  },
  {
    path: '/VideoDetail',
    name: 'VideoDetail',
    component: () => import('../views/VideoDetail.vue')
  },
  {
    path: '/PageType',
    name: 'PageType',
    component: () => import('../views/PageType.vue')
  },
  {
    path: '/VideoPlayer',
    name: 'VideoPlayer',
    component: () => import('../views/VideoPlayer.vue')
  },
  {
    path: '/View',
    name: 'View',
    component: () => import('../views/View.vue')
  },
  {
    path: '/TV-Live',
    name: 'TV-Live',
    component: () => import('../views/TV-Live.vue')
  },
  {
    path: '/TV-Sport',
    name: 'TV-Sport',
    component: () => import('../views/TV-Sport.vue')
  },
  {
    path: '/PageNavigation',
    name: 'PageNavigation',
    component: () => import('../views/PageNavigation.vue')
  },
  {
    path: '/Infor-Board',
    name: 'Infor-Board',
    component: () => import('../views/Infor-Board.vue')
  },
  {
    path: '/Infor-Board-Detail',
    name: 'Infor-Board-Detail',
    component: () => import('../views/Infor-Board-Detail.vue')
  },
  {
    path: '/form/ContactUs',
    name: 'ContactUs',
    component: () => import('../views/form/ContactUs.vue')
  },
  {
    path: '/form/Login',
    name: 'Login',
    component: () => import('../views/form/Login.vue')
  },
  {
    path: '/HomeView',
    name: 'home',
    component: HomeView
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,//process.env.BASE_URL
  routes
})

export default router
