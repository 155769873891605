import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store' 

//设置语言名
import locale from 'element-ui/lib/locale';
import en from 'element-ui/lib/locale/lang/en'; // 引入英文语言包
// 设置ElementUI的语言
locale.use(en);

Vue.config.productionTip = false
Vue.use(ElementUI)

import httpinfo from './assets/js/httpinfo.js'
Vue.prototype.httpinfo = httpinfo
Vue.use(httpinfo)

Vue.prototype.baseURL = 'https://moiya.cn/'
// Vue.prototype.baseURL = 'http://127.0.0.1/'

Vue.prototype.DownloadURL = 'https://tvyuki.top/'
// Vue.prototype.baseURL = 'http://127.0.0.1/'

Vue.prototype.NoKoURL = 'https://api.517jianzhi.com/'
// Vue.prototype.NoKoURL = 'https://moiya.cn/'
// Vue.prototype.NoKoURL = 'http://127.0.0.1/'
Vue.prototype.JumpURL = 'https://moiya.cn/urlto/n78oW1'

Vue.prototype.LiveURL = 'https://sport.tvyuki.top:8089/'
// Vue.prototype.baseURL = 'http://192.168.3.11/'

import axios from 'axios'
Vue.prototype.$axios = axios

// import CompNav from './components/Nav.vue'
// Vue.component('CompNav', CompNav);//注册组件

Vue.prototype.$link_index = '-1' //当前标签位置


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

