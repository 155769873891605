<template>
  <div class="page" v-loading="loading">
    
    <Nav activeIndex="-1"></Nav>

    <el-row justify="start">
      <el-col :span="15">

        <blockquote v-if="dataList.length<=0">
          <el-col :span="24">
            <!-- 信息------找不到内容 -->
            <el-result icon="info" title="정보" subTitle="컨텐츠를 찾을 수 없음">
              <template slot="extra">
                <el-button type="primary" size="medium">back</el-button>
              </template>
            </el-result>
          </el-col>
        </blockquote>
        <blockquote v-else>
          <div v-for="(item,index) in dataList" :key="index">
            <div class="item">
              
              <el-tooltip class="item" effect="dark" :content="item.title" placement="bottom-start">

                <!-- 视频详细 -->
                <router-link v-if="item.line==0" :to="'VideoDetail?id='+item.id">
                  <h3 class="one_line_hidden">{{ item.title }}</h3>
                </router-link>

                <!-- 直接播放视频 torrent详细 -->
                <router-link v-if="item.line==1||item.line==2" :to="'View?id='+item.id">
                  <h3 class="one_line_hidden">{{ item.title }}</h3>
                </router-link>
              
              </el-tooltip>
              <div>
                
                <div class="content">
                  {{ item.intro }}
                </div>
                
                
                <el-row justify="start" class="bottom">
                  <el-col :span="15" class="one_line_hidden">
                    출처
                  </el-col>
                  <el-col :span="9" justify="end" class="one_line_hidden">
                    <i class="el-icon-link"></i>
                    
                    <span style="padding-left: 5px;" v-if="item.line==0||item.line==1"> 
                      {{ item.url }}
                    </span>
                    
                    <span style="padding-left: 5px;" v-if="item.line==2"> 
                      torrent
                    </span>

                  </el-col>
                </el-row>
              </div>

              <!-- <el-collapse accordion>
                <el-collapse-item title="m3u8">
                  <div>http://</div>
                </el-collapse-item>
                <el-collapse-item title="player">
                  <div>url</div>
                </el-collapse-item>
              </el-collapse> -->

            </div>
          </div>
        </blockquote>

      </el-col>
      <el-col :span="9" justify="end">
        [광고 영역]
      </el-col>
    </el-row>


    <div style="padding-top: 20px;" >
      <el-pagination
        background
        layout="prev, pager, next"
        @prev-click="prevPage"
        @next-click="nextPage"
        @current-change="currentChange"
        :pager-count="5"
        :page-size="10"
        :current-page="pagePage"
        :total="Total"> 
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Nav from '@/components/Nav.vue'
export default {
  name: 'PageSearch',
  components: {
    Nav
  },
  data() {
      return {
        loading:true,
        dataList:[],
        pageKeys:'',
        pagePage:0,
        pageTotal:0,
        Total:0,
    };
  },
  created() {
    // console.log(this.$route.query.keys) // 输出: 123
    

    this.pagePage = parseInt(this.$route.query.page)
    this.pageKeys = this.$route.query.keys

    this.loadPage("keys=" +this.pageKeys+ "&page="+this.pagePage)
  },
  watch: {
    // 监听路由对象 $route 的变化
    '$route': {
      handler: function (to, from) {
        // 路由变化时执行的操作
        this.onRouteChange(to, from);
      },
      // 设置为深度监听
      deep: true
    }
  },
  methods: {
    loadPage(Query){
      
      this.dataList = []
      var that = this,dataList = this.dataList

      
      this.httpinfo.GetInfo(this.baseURL + "search?"+Query, function(e) {
        if(e.data.code==0){

          that.pageTotal = e.data.pagecount
          that.Total = e.data.count

          e.data.data.forEach(element => {
            dataList.push(element)
          });
          
          dataList = []

        }else{
          that.$message.error(e.data.msg)
        }

        that.loading = false
      });
    },
    prevPage(e){
      // console.log(e)
    },
    nextPage(e){
      // console.log(e)
    },
    currentChange(e){
      // console.log(e)
      // console.log(this.$router.currentRoute.fullPath)
      this.goTo('/PageSearch?keys='+this.pageKeys+'&page='+e)
    },
    goTo(path) {
      if (path !== this.$router.currentRoute.fullPath) {
          this.loading = true
          this.$router.push(path);
      }
    },
    onRouteChange(to, from) {
      // // 你的逻辑代码
      // // console.log('Route changed from', from.path, 'to', to.path);
      this.loadPage('keys='+to.query.keys+'&page='+to.query.page)
    }
  }
}
</script>
<style>
.item{
  color: #409EFF;
  padding: 0px;
  text-align: left;
}
.item .content{
  color: #999;
  padding-left: 6px;
  word-wrap:break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 5.5em; /* 行高1.1em的时候，3em就是3行，5.5em就是5行 */
}
.item .bottom{
  padding: 10px 0;
  color: #aaa;
  font-size: 9pt;
}
</style>