<template>
  <div class="">

    <div class="box">
      <div style="width: 20%;">

        <transition name="el-zoom-in-top">
          <div v-show="!menuShow" >
            <el-button size="mini" @click="drawer = true" type="primary" icon="el-icon-s-fold"></el-button>
          </div>
        </transition>

      </div>
      <div style="width: 80%;text-align: right;">


        <div style="color: #409eff;font-size: 9pt;padding: 0 10px;">

          <span v-if="httpinfo.getCookie('userlogin')!==''&&httpinfo.getCookie('userlogin')!==undefined">
            <span @click="goTo('/form/Myinfo')"><i class="el-icon-user-solid loginIcon"></i>개인 정보 </span>
          </span>
          <span v-else>
            <span @click="goTo('/form/Login')"><i class="el-icon-user loginIcon"></i>로그인 </span>
          </span>
          
          <span @click="eye_protection_mode(eyeMode)"><i :class="eyeMode + ' loginIcon'"></i>야간모드  </span>
          <!-- <span><i class="el-icon-ice-cream-round loginIcon"></i>注册  </span>
          <span><i class="el-icon-user loginIcon"></i>登录  </span> -->
          <span @click="goTo('/form/ContactUs')"><i class="el-icon-service loginIcon"></i>문의하기 </span>
        </div>

      </div>
    </div>

    
    <el-drawer
      style="width: 500px;"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose">
      <div ><Nav :collapse="navCollapse" :unline="false" :activeIndex="this.$link_index"></nav></div>
    </el-drawer>
    

  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Login from '@/components/Login.vue'

export default {
  name: 'ComLogin',
  props: {
    menuShow: Boolean,
    navCollapse:Boolean,
    Showdrawer:Boolean
  },
  components: {
    Nav
  },
  data() {
    return {
      drawer: this.Showdrawer,
      direction: 'ltr',
      eyeMode: 'el-icon-moon'
    };
  },
  watch: {
    // 监听路由对象 $route 的变化
    '$route': {
      handler: function (to, from) {
        // 路由变化时执行的操作
        this.onRouteChange(to, from);
      },
      // 设置为深度监听
      deep: true
    }
  },
  mounted (){
   
  },
  methods: {
    handleClose(done) {
      // console.log(done)
      this.drawer=false
    },
    eye_protection_mode(e){
      try {
        
        if(e=='el-icon-moon'){
          this.eyeMode = 'el-icon-sunny'
          document.body.className='eye-protection-mode'

          document.getElementById('img_video').className="img_video_"
          this.httpinfo.setData('eyemode','sunny')

        }else{
          this.eyeMode = 'el-icon-moon'
          document.body.className=""

          document.getElementById('img_video').className="img_video"
          this.httpinfo.setData('eyemode','moon')

        }

      } catch (error) {
        
      }


    },
    onRouteChange(to, from) {
      // console.log(this.$route.fullPath)
      // console.log(window.location.href)
      try {
        this.eye_protection_mode(this.eyeMode)
        this.eye_protection_mode(this.eyeMode)
      } catch (error) {
        this.eye_protection_mode(this.eyeMode)
        // console.log("*************")
      }
      
    },
    goTo(path) {
      if (path !== this.$router.currentRoute.fullPath) {
          this.$router.push(path);
      }
    }
  }
}
</script>
<style>
.loginIcon{
  font-size: 20px;
  padding: 0px 5px 0px 20px;
}
.box {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
    align-content: space-between;
}
</style>